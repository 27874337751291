import * as React from 'react';
import { graphql } from 'gatsby';
import { BuilderComponent } from '@builder.io/react';
import { Helmet } from 'react-helmet';
import Link from '../components/Link/Link';

const defaultDescription = `What if you could change somebody's life over one Nightfall?`;

const defaultTitle = 'Project Nightfall Philanthropy | Home';
const faviconUrl = 'https://files.projectnightfall.org/assets/others/pno_favicon_16px.png';

function PageTemplate({ data }) {
  const models = data?.allBuilderModels;
  const page = models.page[0]?.content;

  if (page == null) window.location = '/not-found';

  return (
    <>
      <Helmet>
        <title>{(page && page.data.title) || defaultTitle}</title>
          <link rel="icon" type="image/x-icon" href={faviconUrl}></link>
        <meta
          name="description"
          content={(page && page.data.description) || defaultDescription}
        />

        <meta property="og:title" content={(page && page.data.title) || defaultTitle} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://files.projectnightfall.org/assets/others/pno_hero_image.jpg" />
        <meta property="og:url" content="https://projectnightfall.org" />
        <meta name="twitter:card" content="summary_large_image" />

        <meta property="og:description" content={(page && page.data.description) || defaultDescription} />
        <meta property="og:site_name" content={defaultTitle} />
        <meta name="twitter:image:alt" content={defaultTitle} />
      </Helmet>
      <div id="fb-root"></div>
      <script async defer crossOrigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v14.0"
              nonce="LY7LCApB"></script>
      <BuilderComponent
        renderLink={Link}
        name="page"
        content={page}
      />
    </>
  );
}

export default PageTemplate;

export const pageQuery = graphql`
  query($path: String!) {
    allBuilderModels {
      page(
        target: { urlPath: $path }
        limit: 1
        options: { cachebust: true }
      ) {
        content
      }
    }
  }
`;
